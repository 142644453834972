import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Button from '../../UI/Button';
import ErrorComponent from '../../UI/ErrorComponent';
import styles from './styles.module.scss';

type AuthFormLayoutProps = {
  submit: () => void;
  title: string;
  copySubtitle: string;
  subtitle?: string;
  isDirty: boolean;
  submitButtonTitle: string;
  paragraph: string;
  linkTitle: string;
  link: string;
  error?: FetchBaseQueryError | SerializedError;
  googleButton?: ReactNode;
};

// eslint-disable-next-line import/prefer-default-export
export const AuthFormLayout: FC<PropsWithChildren<AuthFormLayoutProps>> = ({
  submit,
  title,
  copySubtitle,
  subtitle,
  children,
  isDirty,
  submitButtonTitle,
  paragraph,
  linkTitle,
  link,
  error,
  googleButton,
}) => {
  const isSubtitleExist = !!subtitle?.length;

  return (
    <div className={styles['auth-form-container']}>
      <Form className={styles['auth-form']} onSubmit={submit}>
        <div className={styles['auth-form_copy']}>
          <h1 className={styles['auth-form_copy-title']}>{title}</h1>
          <p className={styles['auth-form_copy-subtitle']}>{copySubtitle}</p>
        </div>
        {googleButton}
        <p hidden={!isSubtitleExist} className={styles['auth-form_subtitle']}>
          {subtitle}
        </p>
        <ErrorComponent error={error} />
        {children}
        <Button
          style={{ marginTop: '32px' }}
          type="submit"
          variant="primary"
          disabled={!isDirty}
          className={styles['auth-form_submit']}
        >
          {submitButtonTitle}
        </Button>
        <p className={styles['auth-form_signup']}>
          {paragraph}
          <span className="auth-form_signup-link">
            <Link to={link}>{linkTitle}</Link>
          </span>
        </p>
      </Form>
    </div>
  );
};
