import classNames from 'classnames';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';

import ROUTES from '../../../../routes';
import { Staff } from '../../../../store/features/cvs/types';
import Button from '../../Button';
import Icons, { IconType } from '../../Icons';
import Image from '../../Image';
import Skill from '../../Skill';
import './styles.scss';

interface ICVPreview {
  profile: Staff;
  onSelect(id?: string): void;
  selected: boolean;
}

export default function CVPreviewCard({ profile, onSelect, selected = false }: ICVPreview) {
  const navigate = useNavigate();

  return (
    <Card onClick={() => onSelect(profile.id)} className={classNames({ active: selected })}>
      <div className="cv-preview-card">
        <div className="cv-preview-card--header">
          <div className="cv-preview-card--photo-container">
            {profile?.avatar ? (
              <Image
                className="cv-preview-card--photo"
                src={profile?.avatar}
                alt={`${profile.first_name} ${profile.last_name}`}
              />
            ) : (
              <Icons type={IconType.LogoGreen} alt={`${profile.first_name} ${profile.last_name}`} />
            )}
          </div>
          <div className="cv-preview-card--container">
            <h3 className="cv-preview-card--fullname">
              {`${profile.first_name} ${profile.last_name}`}
              {/* TODO: fix next line */}
              {false && <Icons type={IconType.TopRange} />}
            </h3>
            <h4 className="cv-preview-card--role">
              <Icons type={IconType.Dev} />
              {profile.speciality}
            </h4>
          </div>
        </div>
        <div className="cv-preview-card--body">
          <div className="cv-preview-card--technologies">
            {profile.technical_skills?.programming?.map((skill) => (
              <Skill name={skill.name} key={skill.name} />
            ))}
            {profile.technical_skills?.communication?.map((skill) => (
              <Skill name={skill.name} key={skill.name} />
            ))}
          </div>
          <div className="cv-preview-card--delimiter" />
          <div className="cv-preview-card--expirience-container">
            <div className="cv-preview-card--expirience">Projects : {profile.projects_num}</div>
            <div className="cv-preview-card--expirience">Experience : {profile.experience} years</div>
          </div>
        </div>
        <div className="cv-preview-card--footer">
          <Button onClick={() => navigate(`${ROUTES.CV_LIST}/${profile.id}`)}>More info</Button>
        </div>
      </div>
    </Card>
  );
}
