import { Dispatch, SetStateAction, useState } from 'react';

import { useDragAndDropUpload } from '../../../helpers/useDragAndDropUpload';

type UseUploadScreenshotProps = {
  setFiles: Dispatch<SetStateAction<FileList | File[] | null>>;
  allowedExtensions: string[];
};

const useUploadFiles = ({ setFiles, allowedExtensions }: UseUploadScreenshotProps) => {
  const [urlFiles, setUrlFiles] = useState<string[]>([]);
  const [drag, setDrag] = useState(false);
  const { onDropHandler, dragStartHandler, dragLeaveHandler } = useDragAndDropUpload(
    setDrag,
    setFiles,
    allowedExtensions,
    setUrlFiles,
  );

  const cancelIcon = (photo: string, id?: number) => {
    setUrlFiles((prev) => prev.filter((img) => img !== photo));
    setFiles((prev) => Array.from(prev as Iterable<File> | ArrayLike<File>).filter((_, objId) => objId !== id));
  };

  const rootProps = {
    onDragStart: dragStartHandler,
    onDragLeave: dragLeaveHandler,
    onDragOver: dragStartHandler,
    onDrop: onDropHandler,
  };

  return {
    drag,
    cancelIcon,
    dragStartHandler,
    dragLeaveHandler,
    onDropHandler,
    urlFiles,
    rootProps,
    setUrlFiles,
  };
};

export default useUploadFiles;
