import classNames from 'classnames';
import BootstrapBtn, { ButtonProps } from 'react-bootstrap/Button';

function Button({
  type = 'submit',
  variant = 'primary',
  disabled = false,
  className,
  children,
  ...buttonProps
}: ButtonProps) {
  return (
    <BootstrapBtn
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
      type={type}
      variant={variant}
      disabled={disabled}
      className={classNames(className, 'btn-scs')}
    >
      {children}
    </BootstrapBtn>
  );
}

export default Button;
