import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

interface IImage {
  src: string;
  className: string;
  alt: string;
}

export default function Image({ src, className, alt }: IImage) {
  const [show, setShow] = useState(false);

  const onShow = () => {
    setShow(true);
  };
  const onHide = () => {
    setShow(false);
  };

  return (
    <div onClick={(evt: React.SyntheticEvent) => evt.stopPropagation()} role="presentation">
      <div
        onClick={onShow}
        className={className}
        style={{ backgroundImage: `url("${src}")` }}
        role="presentation"
        aria-label={alt}
      />
      <Modal show={show} enforceFocus={false} restoreFocus backdrop onHide={onHide}>
        <img src={src} alt={alt} />
      </Modal>
    </div>
  );
}
