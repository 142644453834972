import { Dispatch, DragEvent, SetStateAction } from 'react';

import { makeUrlContent } from './makeUrlContent';

export const useDragAndDropUpload = (
  setDrag: Dispatch<SetStateAction<boolean>>,
  setFilesToForm: Dispatch<SetStateAction<null | FileList | File[]>>,
  allowedExtension: string[],
  setUrl: Dispatch<SetStateAction<string[]>>,
) => {
  const dragStartHandler = (e: DragEvent) => {
    e.preventDefault();
    setDrag(true);
  };
  const dragLeaveHandler = (e: DragEvent) => {
    e.preventDefault();
    setDrag(false);
  };
  const onDropHandler = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const localFiles = e.dataTransfer?.files;
    setFilesToForm(localFiles);
    makeUrlContent(allowedExtension, localFiles, setUrl);
    setDrag(false);
  };

  return { dragStartHandler, dragLeaveHandler, onDropHandler };
};
