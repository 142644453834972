export function filterOnExistValue(val: any) {
  return !!val;
}

export function addOrRemove<Type>(list: Type, data: Type, key: keyof Type) {
  if (!Array.isArray(list)) {
    return data;
  }

  if (!key && (list as Array<Type>).some((item: Type) => item === data)) {
    return (list as Array<Type>).filter((item: Type) => item !== data);
  }

  if (
    Array.isArray(key) &&
    (list as Array<Type>).some((item: Type) => {
      // @ts-ignore
      return key.every((itemKey) => item[itemKey] === data[itemKey]);
    })
  ) {
    // @ts-ignore
    return (list as Array<Type>).filter((item: Type) => !key.every((itemKey) => item[itemKey] === data[itemKey]));
  }

  if (!Array.isArray(key) && (list as Array<Type>).some((item: Type) => item[key] === data[key])) {
    return (list as Array<Type>).filter((item: Type) => item[key] !== data[key]);
  }

  return [...list, data];
}
