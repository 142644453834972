import classNames from 'classnames';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';

import fakeLogo from '../../../../assets/png/fake-logo.png';
import ROUTES from '../../../../routes';
import { Project } from '../../../../store/models/Project';
import Button from '../../Button';
import Icons, { IconType } from '../../Icons';
import './styles.scss';

function ListItem({
  item,
  handleSelect,
  isSelected,
}: {
  item: Project;
  handleSelect: (id: Project['id']) => void;
  isSelected: boolean;
}) {
  const navigate = useNavigate();

  return (
    <Card className={classNames({ active: isSelected })} onClick={() => handleSelect(item.id as string)}>
      <div className="project-preview">
        <div className="d-flex justify-content-start align-items-center">
          {/* TODO: remove ts ignore, when the icon field will be ready */}
          {/* @ts-ignore */}
          <img src={item?.logo || fakeLogo} alt="logo" className="project-preview-logo" />
          <div className="project-preview-header">
            <div className="project-preview-title">{item.title}</div>
            {item.location && (
              <div className="project-preview-location">
                <Icons type={IconType.ProjectLocation} />
                {item.location}
              </div>
            )}
          </div>
        </div>
        <div className="project-preview-overview">{item.overview}</div>
        <div className="project-preview-dates">
          <Icons type={IconType.ProjectDuration} />
          {item.start_project}
          {' - '}
          {item.finish_project}
        </div>
        <div className="project-preview-footer">
          <div className="project-preview-footer-button">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                navigate(`${ROUTES.PROJECT_LIST}/${item.id}`);
              }}
            >
              More info
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default ListItem;
