import React, { ChangeEvent, FC } from 'react';

import styles from './styles.module.scss';

type FormInputProps = {
  title: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>, name: string) => void;
  // value: string;
};

const FormInput: FC<FormInputProps> = ({ title, handleChange }) => {
  const name = title.toLowerCase().slice(0, -1);

  return (
    <label className={styles.infoSection} htmlFor={title}>
      <h4 className={styles.title}>{title}</h4>
      <input name={title} type="text" onChange={(e) => handleChange(e, name)} />
    </label>
  );
};

export default FormInput;
