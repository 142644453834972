import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { LoginForm } from './components/Forms';
import { SignUpForm } from './components/Forms/SignUpForm';
import AuthLayout from './components/Layout/AuthLayout';
import CataloguePageLayout from './components/Layout/Page';
import ROUTES from './routes';
import AddNewCase from './screens/Case/AddNew';
import CasesList from './screens/Case/List';
import CasePreview from './screens/Case/View';
import AddNewCV from './screens/CV/AddNew';
import CVListPage from './screens/CV/List';
import CVPage from './screens/CV/View';
import { store } from './store';

import './App.scss';

export default function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route element={<CataloguePageLayout />}>
            <Route path={ROUTES.CV_LIST} element={<CVListPage />} />
            <Route path={ROUTES.CV_VIEW} element={<CVPage />} />
            <Route path={ROUTES.CV_ADD} element={<AddNewCV />} />
            <Route path={ROUTES.PROJECT_LIST} element={<CasesList />} />
            <Route path={ROUTES.PROJECT_ADD} element={<AddNewCase />} />
            <Route path={ROUTES.PROJECT_EDIT} element={<AddNewCase />} />
            <Route path={ROUTES.PROJECT_VIEW} element={<CasePreview />} />
            <Route path="*" element={<Navigate to={ROUTES.PROJECT_LIST} replace />} />
          </Route>
          <Route element={<AuthLayout />}>
            <Route path={ROUTES.LOGIN} element={<LoginForm />} />
            <Route path={ROUTES.SIGNUP} element={<SignUpForm />} />
            <Route path="*" element={<Navigate to={ROUTES.LOGIN} replace />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}
