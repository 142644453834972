import { useNavigate } from 'react-router-dom';

import arrowBackImg from '../../../assets/png/ArrowBack.png';
import styles from './styles.module.scss';

const ArrowBackButton = () => {
  const nav = useNavigate();
  return (
    <button type="button" className={styles.arrowBackContainer} onClick={() => nav(-1)}>
      <img src={arrowBackImg} alt="back" />
    </button>
  );
};

export default ArrowBackButton;
