import { createAction, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

import { ACTION_RESET_FILTERS, ACTION_SET_FILTERS, ACTION_SET_SEARCH, REDUCER_PROJECT } from './constants';
import { IProjectState, ProjectFilters } from './types';

const initialFilterState: ProjectFilters = {
  technology: [],
  country: undefined,
  industry: [],
  platform: [],
};

const initialState: IProjectState = {
  list: [],
  filters: initialFilterState,
  search: '',
  activeCase: null,
  error: '',
  projectByID: {
    id: '',
    location: null,
    technology: [{ name: '', id: '', icon: '' }],
  },
};

export const resetFilters = createAction<void>(`${REDUCER_PROJECT}:${ACTION_RESET_FILTERS}`);
export const setFilter = createAction<any>(`${REDUCER_PROJECT}:${ACTION_SET_FILTERS}`);
export const setSearch = createAction<string>(`${REDUCER_PROJECT}:${ACTION_SET_SEARCH}`);

const projectSlice = createSlice<IProjectState, SliceCaseReducers<IProjectState>, typeof REDUCER_PROJECT>({
  name: REDUCER_PROJECT,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetFilters, (state) => {
      state.filters = initialFilterState;
    });
    builder.addCase(setFilter, (state, { payload: { type, data } }) => {
      state.filters = {
        ...state.filters,
        [type]: data,
      };
    });
    builder.addCase(setSearch, (state, { payload }) => {
      state.search = payload;
    });
  },
});
export const name = REDUCER_PROJECT;
export default projectSlice.reducer;
