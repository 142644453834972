import { FC } from 'react';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';

import { loginOptions } from '../../../helpers/formOptions';
import ROUTES from '../../../routes';
import { useSignInMutation } from '../../../store/features/auth/service';
import AuthFormInput from '../../UI/AuthFormInput';
import { AuthFormLayout } from '../AuthFormLayout';

type FormValues = {
  email: string;
  username: string;
  password: string;
  reset: () => any;
};

// eslint-disable-next-line import/prefer-default-export
export const LoginForm: FC = () => {
  const { control, handleSubmit, reset } = useForm<FormValues>(loginOptions);
  const { isDirty } = useFormState({ control });
  const [signIn] = useSignInMutation();

  const onSubmit: SubmitHandler<FormValues> = (d) => {
    const data = { ...d, username: d.email };
    signIn(data);
    reset();
  };

  return (
    <AuthFormLayout
      isDirty={isDirty}
      submit={handleSubmit(onSubmit)}
      copySubtitle="Welcome back! Please enter your details."
      link={ROUTES.SIGNUP}
      linkTitle="Sign up"
      submitButtonTitle="Sign In"
      paragraph="Don’t have an account?"
      title="Welcome Back"
    >
      <AuthFormInput name="email" control={control} label="Email" type="email" placeholder="Enter your email" />
      <AuthFormInput name="password" control={control} label="Password" type="password" placeholder="*******" />
    </AuthFormLayout>
  );
};
