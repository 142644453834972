import { LoginResponse, SignupReponse } from './models';
import { setToken } from './reducer';

export async function onAuthQueryStarted<D extends LoginResponse | SignupReponse>(
  args: any,
  { dispatch, queryFulfilled }: any,
) {
  try {
    const { data }: { data: D } = await queryFulfilled;

    if ('key' in data) {
      await dispatch(setToken(data.key));
    } else if ('token' in data) {
      await dispatch(setToken(data.token));
    } else {
      throw new Error('Invalid response type');
    }
  } catch (error: any) {
    console.log(error);
  }
}
