import { filterOnExistValue } from '../../../helpers/array';
import { Language } from '../../models/Language';
import { Project } from '../../models/Project';
import { CvsFilters, Staff } from './types';

export function specialityFilter(cvsList: Staff[]): string[] {
  if (!cvsList) {
    return [];
  }

  return cvsList
    .map((profile: Staff) => {
      return profile.speciality || '';
    })
    .filter(filterOnExistValue)
    .reduce<string[]>((specialities: string[], speciality: string) => {
      if (specialities.length && specialities.some((spclty) => spclty === speciality)) {
        return specialities;
      }
      return [...specialities, speciality];
    }, []);
}

export function experienceFilter(cvsList: Staff[]): number[] {
  return cvsList
    .map((profile: Staff) => {
      return profile.experience || 0;
    })
    .filter(filterOnExistValue)
    .reduce<number[]>((experiences: number[], experience: number) => {
      if (experiences.length && experiences.some((exp) => exp === experience)) {
        return experiences;
      }
      return [...experiences, experience];
    }, []);
}

export function projectFilter(cvsList: Staff[]): Project[] {
  return cvsList
    .map((profile: Staff) => {
      return profile.project;
    })
    .flat()
    .reduce<Project[]>((projects: Project[], project: Project) => {
      if (projects.length && projects.some((projectItem) => projectItem.id === project.id)) {
        return projects;
      }
      return [...projects, project];
    }, []);
}

export function isLangUnique(lang: Language) {
  return (language: Language) => {
    return language.name === lang.name && lang.language_level === language.language_level;
  };
}

export function languageFilter(cvsList: Staff[]) {
  if (!cvsList) {
    return [];
  }

  return cvsList
    .map((profile: Staff) => {
      return profile.languages;
    })
    .flat()
    .reduce<Language[]>((languages: Language[], lang: Language) => {
      if (languages.length && languages.some(isLangUnique(lang))) {
        return languages;
      }
      return [...languages, lang];
    }, []);
}

export function filteredCVList(list: Staff[], filters: CvsFilters, search: string): Staff[] {
  return list.filter((profile) => {
    return (
      // Search
      (profile.first_name?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase()) ||
        profile.last_name?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase())) &&
      // Technology filter
      (!filters?.technology.length ||
        profile?.technical_skills?.programming?.some((technology) =>
          filters.technology.some((profileTech) => profileTech.name === technology.name),
        )) &&
      // Speciality filter
      (!filters?.speciality.length || filters?.speciality?.some((speciality) => speciality === profile?.speciality)) &&
      // Experience filter
      (!filters?.experience.length ||
        filters?.experience?.some(
          (experience) => Number(profile.experience) <= experience.max && Number(profile?.experience) >= experience.min,
        )) &&
      // Project filter
      (!filters?.project.length ||
        profile?.project?.some((profileProject) =>
          filters.project.some((project) => project.id === profileProject.id),
        )) &&
      // Language filter
      (!filters?.language.length ||
        profile?.languages?.some((profileLanguage) =>
          filters?.language.some(
            (language) =>
              profileLanguage.name === language.name && profileLanguage.language_level === language.language_level,
          ),
        ))
    );
  });
}
