import { ChangeEvent, FC } from 'react';
import Form from 'react-bootstrap/Form';

interface InputProps {
  type: string | 'text';
  label: string;
  value: string;
  placeholder: string;
  className?: string;
  error?: any;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Input: FC<InputProps> = ({ placeholder, value, type, label, className = undefined, error, onChange }) => (
  <Form.Group className={className}>
    <Form.Label>{label}</Form.Label>
    <Form.Control
      id={value}
      type={type}
      isInvalid={!!error}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
    <Form.Control.Feedback type="invalid">{error ? error.message : null}</Form.Control.Feedback>
  </Form.Group>
);

export default Input;
