import classNames from 'classnames';
import { useState } from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import arrow from '../../assets/svg/arrow-back.svg';
import { Screenshot } from '../../store/models/Screenshot';
import styles from './styles.module.scss';

type Props = {
  slides: Screenshot[];
};

const Slider = ({ slides }: Props) => {
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        className={styles.swiperWrapper}
        modules={[Pagination, Navigation]}
        navigation={{ prevEl, nextEl }}
        pagination={{
          type: 'fraction',
          el: '.pagination-custom',
          clickable: true,
          renderBullet: (index, className) => {
            return `<span class="${className}">${index + 1}</span>`;
          },
        }}
        breakpoints={{ 800: { slidesPerView: 2 } }}
      >
        {slides.map((slide, i) => (
          <SwiperSlide key={slide.id} className={styles.swiperSlide}>
            <img src={slide?.file && slide.file} alt={`slide-${i}`} />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className={styles.sliderControls}>
        <div className={classNames(styles.pagination, 'pagination-custom')} />

        <div className={styles.navigationBlock}>
          <div ref={(node) => setPrevEl(node)}>
            <img src={arrow} alt="prev" />
          </div>

          <div ref={(node) => setNextEl(node)}>
            <img src={arrow} alt="next" className={styles.arrowNext} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Slider;
