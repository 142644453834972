import { useAppSelector } from '../../../index';
import { REDUCER_PROJECT } from '../constants';
import { filteredProjectList } from '../selectors';
import { useGetProjectsQuery } from '../service';

export default function useProjectList() {
  const filters = useAppSelector((state) => state[REDUCER_PROJECT].filters);
  const search = useAppSelector((state) => state[REDUCER_PROJECT].search);

  const { data: list } = useGetProjectsQuery('', {
    selectFromResult: (response) => {
      return {
        ...response,
        data: filteredProjectList(response?.data || [], filters, search),
      };
    },
  });

  return { list };
}
