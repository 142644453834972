/* eslint-disable */
// TODO: remove all eslint issues
import dayjs from 'dayjs';
import { ChangeEvent, FormEvent, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactFlagsSelect from 'react-flags-select';

import styles from './styles.module.scss';
import UploadImage from '../UI/UploadImage';
import FormInput from '../UI/FormInput';
import TechnologiesButtons from '../UI/TechnologiesButtons';
import FormTextarea from '../UI/FormTextarea';
import UploadFiles from '../UI/UploadFiles';
import uploadImage from '../../assets/png/upload.png';
import Button from '../UI/Button';
import { Project } from '../../store/models/Project';
import { Technology } from '../../store/features/filters/models';

interface FormAddCaseProps {
  selectedCase: Project | null;
  onSubmit: (data: any) => void;
}

const allowedExtensions = ['image/png', 'image/jpeg', 'image/jpg'];

function FormAddCase({ onSubmit }: FormAddCaseProps) {
  const [selectedTech, setSelectedTech] = useState<Technology[]>([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndtDate] = useState(new Date());
  const [files, setFiles] = useState<FileList | File[] | null>(null);
  const [icon, setIcon] = useState<FileList | null>(null);
  const [formState, setformState] = useState({
    title: '',
    overview: '',
    about_product: '',
    solution: '',
    challenge: '',
    industry: '',
    result: '',
    name: '',
  });

  const screenshots = useMemo(() => {
    if (files) {
      return Array.from(files as FileList).map((item) => item);
    }
  }, [files]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const techId = selectedTech.map((tech) => tech.id);

    const data = {
      technology: techId,
      result: formState.result,
      location: selectedCountry,
      solution: formState.solution,
      logo: icon?.[0],
      title: formState.name,
      overview: formState.overview,
      industry: formState.industry,
      start_project: dayjs(startDate).format('YYYY-MM-DD'),
      finish_project: dayjs(endDate).format('YYYY-MM-DD'),
      about_product: formState.about_product,
      challenge: formState.challenge,
      screenshots: screenshots,
    };
    onSubmit(data);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, name: string) => {
    const value = e.target.value as string | FileList;
    setformState({
      ...formState,
      [name]: value,
    });
  };

  const handleTechSelect = (tech: Technology): void => {
    if (selectedTech.some((item) => item.name === tech.name)) {
      setSelectedTech((prev) => prev.filter((item) => item.name !== tech.name));
    } else {
      setSelectedTech([...selectedTech, tech]);
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.mainFormInfo}>
        <UploadImage
          setIcon={setIcon}
          subtitle="Drop main image here or"
          allowedExtensionForImage={allowedExtensions}
        />
        <div className={styles.info}>
          <FormInput title="Name:" handleChange={handleChange} />

          <label className={styles.select} htmlFor="location">
            <h3 className={styles.title}>Location</h3>
            <ReactFlagsSelect
              className="flags-input"
              id="location"
              selected={selectedCountry}
              onSelect={(code) => setSelectedCountry(code)}
              searchable
            />
          </label>

          <div className={styles.duration}>
            <h5 className={styles.title}>Duration:</h5>
            <div className={styles.durationInputs}>
              <label htmlFor="start">
                <h6 className={styles.subTitle}>Date start</h6>
                <DatePicker
                  selected={startDate}
                  className="form-control"
                  onChange={(date: Date) => setStartDate(date)}
                />
              </label>
              <label htmlFor="end">
                <h6 className={styles.subTitle}>Date end</h6>
                <DatePicker
                  className="form-control"
                  selected={endDate}
                  onChange={(date: Date) => setEndtDate(date)}
                />{' '}
              </label>
            </div>
          </div>

          <FormInput title="Industry:" handleChange={handleChange} />
        </div>
      </div>

      <div className={styles.secondaryFormInfo}>
        <div className={styles.infoSectionTechnologies}>
          <h4 className={styles.title}>Technologies:</h4>
          <TechnologiesButtons selectedTech={selectedTech} setSelectedTech={handleTechSelect} />
        </div>

        <FormTextarea title="About product:" handleChange={handleChange} />

        <FormTextarea title="Challenge:" handleChange={handleChange} />

        <FormTextarea title="Solution:" handleChange={handleChange} />

        <FormTextarea title="Result:" handleChange={handleChange} />

        <div className={styles.infoSectionScreenshots}>
          <h4 className={styles.title}>Screenshots:</h4>
          <UploadFiles
            files={files}
            setFiles={setFiles}
            image={uploadImage}
            allowedExtensions={allowedExtensions}
            subtitle="Drop additional images here or"
            multiple
            accept="image/*"
          />
        </div>
      </div>
      <div className={styles.submitContainer}>
        <Button type="submit" className={styles.submit}>
          Submit
        </Button>
      </div>
    </form>
  );
}

export default FormAddCase;
