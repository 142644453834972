import { FC } from 'react';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import styles from './styles.module.scss';
import { UploadFilesProps } from './types';
import UploadedContent from './UploadedContent';
import UploadInput from './UploadInput';
import useUploadFiles from './useUploadFiles';

const UploadFiles: FC<UploadFilesProps> = ({
  multiple = false,
  accept,
  image,
  subtitle,
  setFiles,
  allowedExtensions,
}) => {
  const { urlFiles, drag, cancelIcon, setUrlFiles, rootProps } = useUploadFiles({
    setFiles,
    allowedExtensions,
  });

  if (drag) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div className={styles.activeDropArea} {...rootProps}>
        Drop file here
      </div>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={styles.screenshotsArea} {...rootProps}>
      <div className={urlFiles.length ? styles.toDropAreaWithImg : styles.toDropArea}>
        <UploadedContent
          urlFiles={urlFiles}
          image={image}
          cancelIcon={cancelIcon}
          subtitle={subtitle}
          accept={accept}
        />
        <UploadInput
          accept={accept}
          multiple={multiple}
          setFiles={setFiles}
          allowedExtensions={allowedExtensions}
          setUrlFiles={setUrlFiles}
        />
      </div>
    </div>
  );
};

export default UploadFiles;
