import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../..';
import { addOrRemove } from '../../../../helpers/array';
import { REDUCER_CV } from '../constants';
import { resetFilters, setFilter } from '../reducer';
import { CvsFilters } from '../types';

export default function useCVFilters() {
  const filters = useSelector<RootState, CvsFilters>((state) => state[REDUCER_CV].filters);
  const dispatch = useDispatch<AppDispatch>();

  const resetCVFilters = () => dispatch(resetFilters());

  const setCVFilter = function setStoreFilter<Type extends keyof CvsFilters>(type: Type, data: any) {
    const key = {
      technology: 'name',
      speciality: '',
      experience: '',
      project: 'id',
      language: ['name', 'language_level'],
    }?.[type] as keyof CvsFilters[Type];

    const filtersByType = filters?.[type];

    return dispatch(setFilter({ type, data: addOrRemove(filtersByType, data, key) }));
  };

  return { filters, resetCVFilters, setCVFilter };
}
