import { projectFilter } from '../../../store/features/cvs/selectors';
import { useGetCVsQuery } from '../../../store/features/cvs/service';
import { Project } from '../../../store/models/Project';
import Button from '../../UI/Button';

interface IProjectFilter {
  selected: Project[];
  onSelect(project: Project): void;
}
export default function ProjectFilter({ selected, onSelect }: IProjectFilter) {
  const { data } = useGetCVsQuery('');

  const list = projectFilter(data || []);

  return (
    <div className="d-flex justify-content-between align-items-center mt-2">
      Projects:
      <div className="d-flex justify-content-end flex-wrap">
        {list?.map((project: Project) => {
          const isSelected = selected.some((selectedProject) => selectedProject.id === project.id);
          return (
            <Button
              variant={isSelected ? 'secondary' : 'outline-secondary'}
              size="sm"
              onClick={() => onSelect(project)}
              key={project.id}
            >
              {project.title}
            </Button>
          );
        })}
      </div>
    </div>
  );
}
