import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../..';
import { addOrRemove } from '../../../../helpers/array';
import { REDUCER_PROJECT } from '../constants';
import { resetFilters, setFilter } from '../reducer';
import { ProjectFilters } from '../types';

export default function useProjectFilters() {
  const filters = useSelector<RootState, ProjectFilters>((state) => state[REDUCER_PROJECT].filters);
  const dispatch = useDispatch<AppDispatch>();

  const resetProjectFilters = () => dispatch(resetFilters());
  const setProjectFilter = function setStoreFilter<Type extends keyof ProjectFilters>(type: Type, data: any) {
    const key = {
      technology: 'name',
      country: '',
      industry: '',
      platform: 'id',
    }?.[type] as keyof ProjectFilters[Type];
    const filtersByType = filters?.[type];

    return dispatch(setFilter({ type, data: addOrRemove(filtersByType, data, key) }));
  };

  return { filters, resetProjectFilters, setProjectFilter };
}
