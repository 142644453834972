import { Dispatch, FC, SetStateAction, ChangeEvent, useState } from 'react';

import uploadImg from '../../../assets/png/upload.png';
import cancelImg from '../../../assets/svg/close-icon.svg';
import { makeUrlContent } from '../../../helpers/makeUrlContent';
import { useDragAndDropUpload } from '../../../helpers/useDragAndDropUpload';
import styles from './styles.module.scss';

type UploadImageProps = {
  setIcon: Dispatch<SetStateAction<FileList | null>>;
  subtitle: string;
  allowedExtensionForImage: string[];
};

const UploadImage: FC<UploadImageProps> = ({ setIcon, subtitle, allowedExtensionForImage }) => {
  const [urlImage, setUrlImage] = useState<string[]>([]);
  const [drag, setDrag] = useState(false);

  const { onDropHandler, dragStartHandler, dragLeaveHandler } = useDragAndDropUpload(
    setDrag,
    setIcon as Dispatch<SetStateAction<FileList | File[] | null>>,
    allowedExtensionForImage,
    setUrlImage,
  );

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const localFiles = (e.target as HTMLInputElement).files;
    setIcon((e.target as HTMLInputElement).files || null);
    makeUrlContent(allowedExtensionForImage, localFiles as FileList, setUrlImage);
  };

  const cancelIcon = () => {
    setUrlImage([]);
    setIcon(null);
  };

  return (
    <div>
      {drag ? (
        <div
          className={styles.activeDropArea}
          onDragStart={(e) => dragStartHandler(e)}
          onDragLeave={(e) => dragLeaveHandler(e)}
          onDragOver={(e) => dragStartHandler(e)}
          onDrop={(e) => onDropHandler(e)}
        >
          {subtitle}
        </div>
      ) : (
        <div
          className={styles.dropArea}
          onDragStart={(e) => dragStartHandler(e)}
          onDragLeave={(e) => dragLeaveHandler(e)}
          onDragOver={(e) => dragStartHandler(e)}
        >
          <div className={styles.toDropArea}>
            {urlImage.length ? (
              <div className={styles.uploadedImageContainer}>
                <img src={urlImage[0]} alt="Upload" />
              </div>
            ) : (
              <div className={styles.imageContainer}>
                <img src={uploadImg} alt="Upload" />
              </div>
            )}

            {urlImage.length ? (
              <button className={styles.cancelButton} type="button" onClick={cancelIcon}>
                <img src={cancelImg} alt="cancel icon" />
              </button>
            ) : (
              <>
                <p className={styles.paragraph}>{subtitle}</p>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="select-image" className={styles.uploadBtn}>
                  Upload image
                </label>
                <input
                  className={styles.uploadInput}
                  accept="image/*"
                  id="select-image"
                  type="file"
                  onChange={handleUpload}
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadImage;
