import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../helpers/fetchBaseQuery';
import { FILTERS_API } from './constants';
import { Countries, Technology } from './models';

export const filtersApi = createApi({
  reducerPath: FILTERS_API,
  baseQuery,

  endpoints: (builder) => ({
    countries: builder.query<Countries, void>({
      query: () => ({
        url: '/api/countries/',
      }),
    }),
    technologies: builder.query<Technology[], void>({
      query: () => ({
        url: '/technology',
      }),
    }),
  }),
});

export const { useCountriesQuery, useTechnologiesQuery } = filtersApi;
