import Collapse from 'react-bootstrap/Collapse';

import ROUTES from '../../../routes';
import useCVFilters from '../../../store/features/cvs/adapters/useCVFilters';
import { CvsFilters } from '../../../store/features/cvs/types';
import { Technology } from '../../../store/features/filters/models';
import useProjectFilters from '../../../store/features/projects/adapters/useProjectFilters';
import { ProjectFilters } from '../../../store/features/projects/types';
import { Language } from '../../../store/models/Language';
import { Project } from '../../../store/models/Project';
import CountryFilter from '../../Forms/CountryFilter';
import ExperienceFilter from '../../Forms/ExperienceFilter';
import IndustryFilter from '../../Forms/IndustryFilter';
import LanguagesFilter from '../../Forms/LanguageFilter';
import PlatformFilter from '../../Forms/PlatformFilter';
import ProjectFilter from '../../Forms/ProjectFilter';
import SpecialityFilter from '../../Forms/SpecialityFilter';
import TechnologiesFilter from '../../Forms/TechnologyFilter';
import { CollapseBooleanState } from './useCollapseState';

interface IFilterButtonsItem {
  collapseState: CollapseBooleanState;
}

const CVsFiltersComponent = ({ collapseState }: IFilterButtonsItem) => {
  const { filters, setCVFilter } = useCVFilters();

  const setFilter = function filtering<Type>(type: keyof CvsFilters) {
    return (data: Type) => setCVFilter(type, data);
  };

  return (
    <>
      <Collapse in={!collapseState.speciality}>
        <div>
          <SpecialityFilter selected={filters.speciality} onSelect={setFilter<string>('speciality')} />
        </div>
      </Collapse>
      <Collapse in={!collapseState.experience}>
        <div>
          <ExperienceFilter selected={filters.experience} onSelect={setFilter<object>('experience')} />
        </div>
      </Collapse>
      <Collapse in={!collapseState.technology}>
        <div>
          <TechnologiesFilter selected={filters.technology} onSelect={setFilter<Technology>('technology')} />
        </div>
      </Collapse>
      <Collapse in={!collapseState.project}>
        <div>
          <ProjectFilter selected={filters.project} onSelect={setFilter<Project>('project')} />
        </div>
      </Collapse>
      <Collapse in={!collapseState.language}>
        <div>
          <LanguagesFilter selected={filters.language} onSelect={setFilter<Language>('language')} />
        </div>
      </Collapse>
    </>
  );
};

const ProjectFiltersComponent = ({ collapseState }: IFilterButtonsItem) => {
  const { filters, setProjectFilter } = useProjectFilters();

  const setFilter = function filtering<Type>(type: keyof ProjectFilters) {
    return (data: Type) => setProjectFilter(type, data);
  };

  return (
    <>
      <Collapse in={!collapseState.location}>
        <div>
          <CountryFilter selected={filters.country} onSelect={setFilter<string>('country')} />
        </div>
      </Collapse>
      <Collapse in={!collapseState.technology}>
        <div>
          <TechnologiesFilter selected={filters.technology} onSelect={setFilter<Technology>('technology')} />
        </div>
      </Collapse>
      <Collapse in={!collapseState.industry}>
        <div>
          <IndustryFilter selected={filters.industry} onSelect={setFilter<string>('industry')} />
        </div>
      </Collapse>
      <Collapse in={!collapseState.platform}>
        <div>
          <PlatformFilter selected={filters.platform} onSelect={setFilter<string>('platform')} />
        </div>
      </Collapse>
    </>
  );
};

export default {
  [ROUTES.PROJECT_LIST]: ProjectFiltersComponent,
  [ROUTES.CV_LIST]: CVsFiltersComponent,
};
