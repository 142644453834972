import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

import Input from '../Input';
import styles from './styles.module.scss';

type AuthFormInputProps = {
  name: string;
  control: Control<any>;
  type: string;
  label: string;
  placeholder: string;
};

const AuthFormInput: FC<AuthFormInputProps> = ({ name, control, type, label, placeholder }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Input
          type={type}
          label={label}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          error={error}
          className={styles['login-form_input']}
        />
      )}
    />
  );
};

export default AuthFormInput;
