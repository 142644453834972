import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = {
  title: string;
};

const PageTitle = ({ title }: Props) => (
  <h2 className={classNames(styles.pageTitle, 'text-start', 'text-lg-center')}>{title}</h2>
);

export default PageTitle;
