import React, { ChangeEvent, FC } from 'react';

import styles from './styles.module.scss';

type FormTextareaProps = {
  title: string;
  handleChange: (e: ChangeEvent<HTMLTextAreaElement>, name: string) => void;
};

const FormTextarea: FC<FormTextareaProps> = ({ title, handleChange }) => {
  const name = title.startsWith('About')
    ? title.toLowerCase().slice(0, -1).split(' ').join('_')
    : title.toLowerCase().slice(0, -1);

  return (
    <label className={styles.infoSection} htmlFor={title}>
      <h4 className={styles.title}>{title}</h4>
      <textarea name={title} onChange={(e) => handleChange(e, name)} />
    </label>
  );
};

export default FormTextarea;
