import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';

import AddNewFileButton from '../../../components/UI/AddNewFileButton';
import ListItem from '../../../components/UI/Card/ProjectPreview';
import DownloadButton from '../../../components/UI/DowloadButton';
import { filterOnExistValue } from '../../../helpers/array';
import ROUTES from '../../../routes';
import useProjectList from '../../../store/features/projects/adapters/useProjectList';
import { Project } from '../../../store/models/Project';

function CasesList() {
  const navigate = useNavigate();
  const { list: projectList } = useProjectList();
  const [casesForPrint, setCasesForPring] = useState<string[]>([]);

  const handlePrintSelect = (id: Project['id']) => {
    const cases = casesForPrint.filter(filterOnExistValue);
    if (cases.includes(id)) {
      setCasesForPring([...cases.filter((item: string) => item !== id)]);
    } else {
      setCasesForPring([...cases, id]);
    }
  };

  const handlePrint = async () => {
    const pdfLink = `${process.env.REACT_APP_API_URL}/projects-to-pdf/?projects=${casesForPrint.toString()}`;
    window.open(pdfLink, '_blank', 'noopener,noreferrer');
  };

  const onClickHandler = () => navigate(ROUTES.PROJECT_ADD);

  return (
    <Container>
      <p className=" mh-2 w-100 d-flex justify-content-end">Projects {projectList.length}</p>
      <div className="row d-flex justify-content-end">
        <DownloadButton selected={casesForPrint} downloadAction={handlePrint} />
      </div>
      <div className="">
        <div className="row grid-layout">
          {projectList?.map((item) => (
            <ListItem
              item={item}
              key={item.id}
              handleSelect={handlePrintSelect}
              isSelected={!!item.id && !!casesForPrint.includes(item.id)}
            />
          ))}
        </div>
      </div>
      <AddNewFileButton onClick={onClickHandler} />
    </Container>
  );
}

export default CasesList;
