import ROUTES from '../../../routes';
import FilterItemExpander from '../../UI/FilterItemExpander';
import { CollapseBooleanState } from './useCollapseState';

interface IFilterButtonsItem {
  active: CollapseBooleanState;
  setActive(key: string): (val: boolean) => void;
}

export default {
  [ROUTES.PROJECT_LIST]: ({ active, setActive }: IFilterButtonsItem) => (
    <>
      {/* Project Filters */}
      <FilterItemExpander isActive={active.location} setActive={setActive('location')} type="location" />
      <FilterItemExpander isActive={active.technology} setActive={setActive('technology')} type="technology" />
      <FilterItemExpander isActive={active.industry} setActive={setActive('industry')} type="industry" />
      <FilterItemExpander isActive={active.platform} setActive={setActive('platform')} type="platform" />
    </>
  ),
  [ROUTES.CV_LIST]: ({ active, setActive }: IFilterButtonsItem) => (
    <>
      {/* CV Filters */}
      <FilterItemExpander isActive={active.speciality} setActive={setActive('speciality')} type="speciality" />
      <FilterItemExpander isActive={active.experience} setActive={setActive('experience')} type="experience" />
      <FilterItemExpander isActive={active.technology} setActive={setActive('technology')} type="technology" />
      <FilterItemExpander isActive={active.project} setActive={setActive('project')} type="project" />
      <FilterItemExpander isActive={active.language} setActive={setActive('language')} type="language" />
    </>
  ),
};
