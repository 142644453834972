import { FC } from 'react';

import styles from './styles.module.scss';

type AddNewFileButtonProps = {
  onClick: () => void;
};

const AddNewFileButton: FC<AddNewFileButtonProps> = ({ onClick }) => {
  return (
    <button className={styles.button} type="button" onClick={onClick}>
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="56" height="56" rx="28" fill="#D25F4D" />
        <path d="M28 21V35" stroke="white" strokeWidth="3" strokeLinecap="round" />
        <path d="M35 28L21 28" stroke="white" strokeWidth="3" strokeLinecap="round" />
      </svg>
    </button>
  );
};

export default AddNewFileButton;
