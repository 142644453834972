import { isLangUnique, languageFilter } from '../../../store/features/cvs/selectors';
import { useGetCVsQuery } from '../../../store/features/cvs/service';
import { Language } from '../../../store/models/Language';
import Button from '../../UI/Button';

interface ILanguagesFilter {
  selected: Language[];
  onSelect(language: Language): void;
}
export default function LanguagesFilter({ selected, onSelect }: ILanguagesFilter) {
  const { data } = useGetCVsQuery('');

  const list = languageFilter(data || []);

  return (
    <div className="d-flex justify-content-between align-items-center mt-2">
      Languages:
      <div className="d-flex justify-content-end flex-wrap">
        {list?.map((lang: Language) => {
          const key = `${lang.name as string},${lang.language_level as string}`;
          const isSelected = selected.some(isLangUnique(lang));
          return (
            <Button
              variant={isSelected ? 'secondary' : 'outline-secondary'}
              size="sm"
              onClick={() => onSelect(lang)}
              key={key}
            >
              {lang.name} ({lang.language_level})
            </Button>
          );
        })}
      </div>
    </div>
  );
}
