import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { REDUCER_AUTH } from './constants';
import { AuthState } from './types';

const initialState: AuthState = {
  token: '',
};

const authSlice = createSlice<AuthState, SliceCaseReducers<AuthState>>({
  name: REDUCER_AUTH,
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
});

export const { setToken } = authSlice.actions;
export const name = REDUCER_AUTH;
export default authSlice.reducer;
