import useResizeObserver from '@react-hook/resize-observer';
import { RefObject, useLayoutEffect, useState } from 'react';

const useWidth = (target: RefObject<HTMLDivElement> | null) => {
  const [width, setWidth] = useState<number | null>(null);

  useLayoutEffect(() => {
    if (!target?.current) return;

    setWidth(target.current.getBoundingClientRect().width);
  }, [target]);

  useResizeObserver(target, (entry) => setWidth(entry.contentRect.width));
  return width;
};

export default useWidth;
