import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

const history = createBrowserHistory();
const app = document.getElementById('root') as HTMLElement;

if (app) {
  // 1. Set up the browser history with the updated location
  // (minus the # sign)
  // eslint-disable-next-line
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  const persistor = persistStore(store);

  // 2. Render our app
  ReactDOM.render(
    <React.StrictMode>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </React.StrictMode>,
    document.getElementById('root') as HTMLElement,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
