enum ROUTES {
  LOGIN = '/login',
  SIGNUP = '/sign-up',
  CATALOGUE = '/catalogue',
  CV_LIST = '/catalogue/cvs',
  CV_ADD = '/catalogue/cvs/add',
  CV_VIEW = '/catalogue/cvs/:id',
  PROJECT_LIST = '/catalogue/projects',
  PROJECT_ADD = '/catalogue/projects/add',
  PROJECT_EDIT = '/catalogue/projects/edit',
  PROJECT_VIEW = '/catalogue/projects/:id',
}

export default ROUTES;
