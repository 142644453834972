import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../helpers/fetchBaseQuery';
import { CVS_API } from './constants';

export const cvsApi = createApi({
  reducerPath: CVS_API,
  baseQuery,
  endpoints: (builder) => ({
    getCVs: builder.query({
      query: () => '/api/staff/staff',
    }),
    getCVById: builder.query({
      query: (id) => `/api/staff/staff/${id}`,
    }),
  }),
});

export const { useGetCVByIdQuery, useGetCVsQuery } = cvsApi;
