import { Navigate, Outlet, useLocation } from 'react-router-dom';

import ROUTES from '../../../routes';
import { useAppSelector } from '../../../store';
import { REDUCER_AUTH } from '../../../store/features/auth/constants';
import FilterBar from '../FilterBar';
import Header from '../Header';
import styles from './styles.module.scss';

export default function CataloguePageLayout() {
  const { pathname } = useLocation();
  const isFiltersShown = [ROUTES.CV_LIST, ROUTES.PROJECT_LIST].includes(pathname as ROUTES);
  const token = useAppSelector((state) => state[REDUCER_AUTH].token);

  if (!token) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper} />
      <Header />
      {isFiltersShown ? <FilterBar /> : null}
      <Outlet />
    </div>
  );
}
