import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../helpers/fetchBaseQuery';
import { PROJECTS_API } from './constants';

export const projectsApi = createApi({
  reducerPath: PROJECTS_API,
  baseQuery,
  endpoints: (builder) => ({
    getProjects: builder.query({
      query: () => '/projects',
    }),
    getProjectById: builder.query({
      query: (id) => `/projects/${id}`,
    }),
    createNewProject: builder.mutation({
      query: (id) => `/projects/${id}`,
    }),
  }),
});

export const { useGetProjectByIdQuery, useGetProjectsQuery, useCreateNewProjectMutation } = projectsApi;
