import { BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import ROUTES from '../../routes';
import { REDUCER_AUTH } from '../features/auth/constants';
import { setToken } from '../features/auth/reducer';
import type { RootState } from '../index';

const queryPreparation = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token =
      (getState() as RootState)[REDUCER_AUTH].token ||
      JSON.parse(localStorage.getItem('persist:root') as string)?.auth?.token;

    if (token) {
      headers.set('authorization', `Token ${token}`);
    }

    return headers;
  },
});

export const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await queryPreparation(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    localStorage.clear();
    window.location.href = ROUTES.LOGIN;
    api.dispatch(setToken(''));
  }

  return result;
};
