import { createAction, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

import { ACTION_RESET_FILTERS, ACTION_SET_FILTERS, ACTION_SET_SEARCH, REDUCER_CV } from './constants';
import { CvsFilters, ICVState } from './types';

/* initial State */

const initialFilterState: CvsFilters = {
  technology: [],
  speciality: [],
  experience: [],
  project: [],
  language: [],
};

const initialState: ICVState = {
  list: [],
  filters: initialFilterState,
  search: '',
  cvById: {
    avatar: '',
    created_at: '',
    cv_file: null,
    education: [{ period_end: 0, period_start: 0, speciality: '', university: '' }],
    experience: 0,
    first_name: '',
    id: '',
    languages: [{ name: '', language_level: '' }],
    last_name: '',
    project: [{ id: '', location: null, technology: [{ name: '', id: '', icon: '' }] }],
    speciality: '',
  },
};

/* action - api adapters */

export const resetFilters = createAction<void>(`${REDUCER_CV}:${ACTION_RESET_FILTERS}`);
export const setFilter = createAction<any>(`${REDUCER_CV}:${ACTION_SET_FILTERS}`);
export const setSearch = createAction<string>(`${REDUCER_CV}:${ACTION_SET_SEARCH}`);

const cvSlice = createSlice<ICVState, SliceCaseReducers<ICVState>, typeof REDUCER_CV>({
  name: REDUCER_CV,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetFilters, (state) => {
      state.filters = initialFilterState;
    });
    builder.addCase(setFilter, (state, { payload: { type, data } }) => {
      state.filters = {
        ...state.filters,
        [type]: data,
      };
    });
    builder.addCase(setSearch, (state, { payload }) => {
      state.search = payload;
    });
  },
});

export const name = REDUCER_CV;
export default cvSlice.reducer;
