import { specialityFilter } from '../../../store/features/cvs/selectors';
import { useGetCVsQuery } from '../../../store/features/cvs/service';
import Button from '../../UI/Button';

interface ISpecialityFilter {
  selected: string[];
  onSelect(speciality: string): void;
}
export default function SpecialityFilter({ selected, onSelect }: ISpecialityFilter) {
  const { data } = useGetCVsQuery('');

  const list = specialityFilter(data || []);

  return (
    <div className="d-flex justify-content-between align-items-center mt-2">
      Speciality:
      <div className="d-flex justify-content-end flex-wrap">
        {list?.map((name: string) => {
          const isSelected = selected.includes(name as string);
          return (
            <Button
              variant={isSelected ? 'secondary' : 'outline-secondary'}
              size="sm"
              onClick={() => onSelect(name as string)}
              key={name}
            >
              {name}
            </Button>
          );
        })}
      </div>
    </div>
  );
}
