import { ChangeEvent, Dispatch, FC, SetStateAction, useCallback } from 'react';

import { makeUrlContent } from '../../../../helpers/makeUrlContent';
import styles from '../styles.module.scss';

type UploadInputProps = {
  setFiles: Dispatch<SetStateAction<FileList | File[] | null>>;
  allowedExtensions: string[];
  setUrlFiles: Dispatch<SetStateAction<string[]>>;
  multiple: boolean;
  accept: string;
};

const UploadInput: FC<UploadInputProps> = ({ multiple, accept, setUrlFiles, setFiles, allowedExtensions }) => {
  const handleUploadImages = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const localFiles = (e.target as HTMLInputElement).files;
      setFiles(localFiles);
      if (localFiles) {
        makeUrlContent(allowedExtensions, localFiles, setUrlFiles);
      }
    },
    [allowedExtensions, setFiles, setUrlFiles],
  );

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="select-multiple-image" className={styles.uploadBtn}>
        Upload image
      </label>
      <input
        multiple={multiple}
        className={styles.uploadInput}
        accept={accept}
        id="select-multiple-image"
        type="file"
        onChange={handleUploadImages}
      />
    </>
  );
};

export default UploadInput;
