import { filterOnExistValue } from '../../../helpers/array';
import { Project } from '../../models/Project';
import { ProjectFilters } from './types';

export function platformFilter(projects: Project[]): string[] {
  if (!projects) {
    return [];
  }

  return projects
    .map((project: Project) => {
      return project.platform || '';
    })
    .filter(filterOnExistValue)
    .reduce((platforms: string[], platform: string) => {
      if (platforms.includes(platform)) {
        return [...platforms];
      }
      return [...platforms, platform];
    }, []);
}

export function industryFilter(projects: Project[]): string[] {
  if (!projects) {
    return [];
  }

  return projects
    .map((project: Project) => {
      return project.industry || '';
    })
    .filter(filterOnExistValue)
    .reduce((industries: string[], industry: string) => {
      if (industries.includes(industry)) {
        return [...industries];
      }
      return [...industries, industry];
    }, []);
}

export function countryFilter(projects: Project[]): string[] {
  if (!projects) {
    return [];
  }

  return projects
    .map((project: Project): string => {
      return project.location || '';
    })
    .filter(filterOnExistValue)
    .reduce((countries: string[], country: string) => {
      if (countries.includes(country)) {
        return [...countries];
      }
      return [...countries, country];
    }, []);
}

export function filteredProjectList(list: Project[], filters: ProjectFilters, search: string): Project[] {
  return list.filter((project) => {
    return (
      // Search
      ((project.title?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase()) &&
        // Countries filter
        !filters?.country) ||
        (project.location && filters?.country && project.location.includes(filters?.country))) &&
      // Platform filter
      (!filters?.platform.length || filters?.platform?.some((platform) => platform === project.platform)) &&
      // Industry filter
      (!filters?.industry.length || filters?.industry?.some((industry) => industry === project.industry)) &&
      // Technology filter
      (!filters?.technology.length ||
        filters?.technology?.some((technology) =>
          project.technology?.some((projectTech) => projectTech?.id === technology.id),
        ))
    );
  });
}
