/* eslint-disable import/no-duplicates */
import classNames from 'classnames';
import { FC, ImgHTMLAttributes } from 'react';

import AWSIcon from '../../assets/svg/Technology/AWS.svg';
import SeleniumIcon from '../../assets/svg/Technology/Celery.svg';
import CCIcon from '../../assets/svg/Technology/Cplus.svg';
import CSharpIcon from '../../assets/svg/Technology/csharp.svg';
import DjangoIcon from '../../assets/svg/Technology/Django.svg';
import EC2Icon from '../../assets/svg/Technology/EC2.svg';
import MatlabIcon from '../../assets/svg/Technology/Matlab.svg';
import NLPIcon from '../../assets/svg/Technology/NLP.svg';
import PostgreSQLIcon from '../../assets/svg/Technology/PostGIS.svg';
import PythonIcon from '../../assets/svg/Technology/Python.svg';
import ReactNativeIcon from '../../assets/svg/Technology/React.svg';
import ReactIcon from '../../assets/svg/Technology/React.svg';
import JavaScriptIcon from '../../assets/svg/Technology/React.svg';

export enum IconType {
  PostgreSQL = 'PostgreSQL',
  Selenium = 'Selenium',
  EC2 = 'EC2',
  CC = 'C/C++',
  Python = 'Python',
  ReactNative = 'ReactNative',
  AWS = 'AWS',
  Django = 'Django',
  Matlab = 'Matlab',
  JavaScript = 'JavaScript',
  React = 'React',
  NLP = 'NLP',
  CSharp = 'C#',
}

const IconByType = {
  [IconType.PostgreSQL]: PostgreSQLIcon,
  [IconType.Selenium]: SeleniumIcon,
  [IconType.EC2]: EC2Icon,
  [IconType.CC]: CCIcon,
  [IconType.Python]: PythonIcon,
  [IconType.ReactNative]: ReactNativeIcon,
  [IconType.AWS]: AWSIcon,
  [IconType.Django]: DjangoIcon,
  [IconType.Matlab]: MatlabIcon,
  [IconType.JavaScript]: JavaScriptIcon,
  [IconType.React]: ReactIcon,
  [IconType.NLP]: NLPIcon,
  [IconType.CSharp]: CSharpIcon,
};

interface IIcons extends ImgHTMLAttributes<HTMLImageElement> {
  type: IconType;
}

const TechnologyIcons: FC<IIcons> = ({ type, ...imageProps }) => (
  <img
    {...imageProps}
    alt={imageProps?.alt || type}
    className={classNames(imageProps.className, 'technology-icons')}
    src={IconByType[type]}
  />
);

export default TechnologyIcons;
