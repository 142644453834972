import ReactFlagsSelect from 'react-flags-select';

import closeIcon from '../../../assets/svg/close-icon.svg';
import { countryFilter } from '../../../store/features/projects/selectors';
import { useGetProjectsQuery } from '../../../store/features/projects/service';

interface ICountryFilter {
  selected?: string;
  onSelect(country: string): void;
}
export default function CountryFilter({ selected, onSelect }: ICountryFilter) {
  const { data: list } = useGetProjectsQuery('', {
    selectFromResult: (response) => ({
      ...response,
      data: countryFilter(response.data || []),
    }),
  });
  const cleanCountryFilter = () => onSelect('');

  return (
    <div className="d-flex justify-content-between align-items-center mt-2">
      <div className="d-flex justify-content-between w-100">
        <ReactFlagsSelect
          className="flags-input w-100"
          id="location"
          countries={list}
          selected={selected || ''}
          onSelect={onSelect}
          searchable
        />
        <button
          type="button"
          className="d-flex justify-content-center align-items-center m-2"
          style={{ width: '30px' }}
          onClick={cleanCountryFilter}
        >
          <img src={closeIcon} alt="clearLocationFilter" />
        </button>
      </div>
    </div>
  );
}
