import classNames from 'classnames';
import React from 'react';
import { Container } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import fakeLogo from '../../../assets/png/fake-logo.png';
import arrowBack from '../../../assets/svg/arrow-back.svg';
import download from '../../../assets/svg/Download_PDF.svg';
import edit from '../../../assets/svg/edit-icon.svg';
import PageTitle from '../../../components/Layout/PageTitle';
import Slider from '../../../components/Slider';
import TechnologyIcons from '../../../components/UI/TechnologyIcons';
import { getCountryName } from '../../../helpers/countyNames';
import ROUTES from '../../../routes';
import { useGetProjectByIdQuery } from '../../../store/features/projects/service';
import styles from './styles.module.scss';

function CasePreview() {
  const params = useParams();
  const { data: project } = useGetProjectByIdQuery(params.id);

  const {
    id,
    logo,
    title,
    about_product: aboutProduct,
    location,
    technology,
    industry,
    start_project: startProject,
    finish_project: finishProject,
    challenge,
    solution,
    result,
    screenshots,
  } = project || {};

  const techToRender = technology?.map((el: any) => (
    <span className="badge custom-badge" key={el.name}>
      {el.icon && <TechnologyIcons type={el.name} />}
      {el.name}
    </span>
  ));

  const handlePrint = async () => {
    const pdfLink = `${process.env.REACT_APP_API_URL}/projects-to-pdf/?projects=${id.toString()}`;
    window.open(pdfLink, '_blank', 'noopener,noreferrer');
  };

  return (
    <Container className="page-container">
      <div className="page-controls d-flex justify-content-between align-items-center mb-3">
        <Link to={ROUTES.PROJECT_LIST} style={{ margin: 0 }}>
          <button type="button">
            <img src={arrowBack} alt="goBack" />
          </button>
        </Link>

        <div>
          <button type="button" className="button-s edit">
            <img src={edit} width="20" alt="edit" />
            Edit
          </button>

          <button type="button" className="button-s download" onClick={handlePrint}>
            <img src={download} width="20" alt="download" />
            Download PDF
          </button>
        </div>
      </div>

      <div className={styles.pageWrapper}>
        <PageTitle title={title || ''} />

        <div className="row">
          <div className="col-lg-6 col-md-12 d-flex justify-content-center">
            <div className={styles.logoWrapper}>
              <img className={styles.logoImage} src={logo || fakeLogo} alt="logo" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className={classNames(styles.infoBlock, 'mb-2')}>
              <h4 className={styles.infoBlockTitle}>Country:</h4>
              <div className={styles.infoBlockInfo}>{getCountryName(location || '')}</div>
            </div>

            <div className={classNames(styles.infoBlock, 'mb-2')}>
              <h4 className={styles.infoBlockTitle}>Duration:</h4>
              <div className={styles.infoBlockInfo}>
                {startProject} - {finishProject}
              </div>
            </div>

            <div className={classNames(styles.infoBlock, 'mb-2')}>
              <h4 className={styles.infoBlockTitle}>Industry:</h4>
              <div className={styles.infoBlockInfo}>
                <span className="badge custom-badge">{industry}</span>
              </div>
            </div>

            <div className={classNames(styles.infoBlock, 'mb-2')}>
              <h4 className={styles.infoBlockTitle}>Technologies:</h4>
              <div className={styles.infoBlockInfo}>{techToRender}</div>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <div className={styles.infoBlock}>
              <h4 className={styles.infoBlockTitle}>About product:</h4>
              <div className={styles.infoBlockInfo}>{aboutProduct}</div>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <div className={styles.infoBlock}>
              <h4 className={styles.infoBlockTitle}>Challenge:</h4>
              <div className={styles.infoBlockInfo}>{challenge}</div>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <div className={styles.infoBlock}>
              <h4 className={styles.infoBlockTitle}>Solution:</h4>
              <div className={styles.infoBlockInfo}>{solution}</div>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <div className={styles.infoBlock}>
              <h4 className={styles.infoBlockTitle}>Result:</h4>
              <div className={styles.infoBlockInfo}>{result}</div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className={styles.infoBlock}>
              <h4 className={styles.infoBlockTitle}>Screenshots:</h4>
              <div className={styles.infoBlockInfo}>
                <Slider slides={screenshots || []} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default React.memo(CasePreview);
