import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../helpers/fetchBaseQuery';
import { AUTH_API } from './constants';
import { Login, LoginResponse, SignupReponse, User } from './models';
import { onAuthQueryStarted } from './utils';

export const authApi = createApi({
  reducerPath: AUTH_API,
  baseQuery,

  endpoints: (builder) => ({
    signIn: builder.mutation<LoginResponse, Login>({
      query: (data) => {
        return {
          url: '/api/auth/login/',
          method: 'POST',
          body: data,
        };
      },
      onQueryStarted: onAuthQueryStarted<LoginResponse>,
    }),
    signUp: builder.mutation<SignupReponse, User>({
      query: (data) => {
        return {
          url: '/api/auth/registration/',
          method: 'POST',
          body: data,
        };
      },
      onQueryStarted: onAuthQueryStarted<SignupReponse>,
    }),
  }),
});

export const { useSignInMutation, useSignUpMutation } = authApi;
