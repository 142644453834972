import FormAddCV from '../../../components/FormAddCV';
import ArrowBackButton from '../../../components/UI/ArrowBackButton';
import styles from './styles.module.scss';

function AddNewCV() {
  return (
    <div className="container">
      <div className="row px-3">
        <ArrowBackButton />
        <div className={styles.formContainer}>
          <FormAddCV />
        </div>
      </div>
    </div>
  );
}

export default AddNewCV;
