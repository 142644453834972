import React from 'react';

import googleLogo from '../../../assets/svg/googleButtonLogo.svg';
import styles from './styles.module.scss';

const GoogleButton = () => {
  return (
    <button type="button" className={styles.googleBtnContainer}>
      <div className={styles.imageContainer}>
        <img src={googleLogo} alt="google" />
      </div>
      <p className={styles.title}>Log in with Google</p>
    </button>
  );
};

export default GoogleButton;
