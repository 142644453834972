import { FC } from 'react';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';

import { signUpOptions } from '../../../helpers/formOptions';
import ROUTES from '../../../routes';
import { User } from '../../../store/features/auth/models';
import { useSignUpMutation } from '../../../store/features/auth/service';
import AuthFormInput from '../../UI/AuthFormInput';
import GoogleButton from '../../UI/GoogleButton';
import { AuthFormLayout } from '../AuthFormLayout';

export const SignUpForm: FC = () => {
  const { control, handleSubmit, reset } = useForm<User>(signUpOptions);
  const { isDirty } = useFormState({ control });

  const [signUp, { error }] = useSignUpMutation();

  const onSubmit: SubmitHandler<User> = (d) => {
    const data = { ...d, last_name: d.username, first_name: d.username };
    signUp(data);
    reset();
  };

  return (
    <AuthFormLayout
      isDirty={isDirty}
      submit={handleSubmit(onSubmit)}
      copySubtitle="Please enter your details."
      link={ROUTES.LOGIN}
      linkTitle="Log in"
      submitButtonTitle="Sign Up"
      paragraph="Already have an account?"
      subtitle="or"
      title="Welcome"
      error={error}
      googleButton={<GoogleButton />}
    >
      <AuthFormInput name="username" control={control} label="Name" type="text" placeholder="Enter your name" />
      <AuthFormInput name="email" control={control} label="Email" type="email" placeholder="Enter your email" />
      <AuthFormInput name="password" control={control} label="Password" type="password" placeholder="*******" />
    </AuthFormLayout>
  );
};
