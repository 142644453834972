import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FormAddCase } from '../../../components';
import ArrowBackButton from '../../../components/UI/ArrowBackButton';
import ROUTES from '../../../routes';
import { RootState } from '../../../store';
import { ProjectCreate } from '../../../store/models/Project';
import styles from './styles.module.scss';

function AddNewCase() {
  const { activeCase, error } = useSelector((state: RootState) => state.projects);
  const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  const onSubmit = async (data: ProjectCreate) => {
    // dispatch(createNewProject(data));
    navigate(ROUTES.PROJECT_LIST);
  };

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  }, [error]);

  return (
    <div className="container">
      <div className="row px-3">
        <ArrowBackButton />
        <div className={styles.formContainer}>
          <FormAddCase onSubmit={onSubmit} selectedCase={activeCase} />
        </div>
      </div>
    </div>
  );
}

export default AddNewCase;
