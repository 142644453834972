import { Dispatch, SetStateAction } from 'react';

export const makeUrlContent = (extensions: string[], files: FileList, handler: Dispatch<SetStateAction<string[]>>) => {
  const filesArray = Array.from(files);
  const isWrongExt = filesArray.some((file) => !extensions.includes(file.type));

  if (files && !isWrongExt) {
    const urlFilesArray = filesArray.map((file) => URL.createObjectURL(file));
    handler((prev) => prev.concat(urlFilesArray));
  } else {
    alert('Wrong type of file ');
  }
};
