import * as yup from 'yup';

const message = '*Required';
const passwordMessage = '* Password required';
const emailMessage = '*Email must be a valid';

const eValidation =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const baseStringConfig = yup.string().required(message);
const passwordConfig = yup.string().min(3).max(30).required(passwordMessage);
const emailConfig = yup
  .string()
  .email(emailMessage)
  .test('Validate Email', emailMessage, (value) => eValidation.test(String(value).toLowerCase()));

export const signUpSchema = yup.object().shape({
  username: baseStringConfig,
  email: emailConfig,
  password: passwordConfig,
});

export const loginSchema = yup.object().shape({
  email: emailConfig,
  password: passwordConfig,
});
