import { FC } from 'react';

import { Technology } from '../../../store/features/filters/models';
import { useTechnologiesQuery } from '../../../store/features/filters/service';
import styles from './styles.module.scss';

type TechnologiesButtonsProps = {
  selectedTech: Technology[];
  setSelectedTech: (tech: Technology) => void;
};

const TechnologiesButtons: FC<TechnologiesButtonsProps> = ({ selectedTech, setSelectedTech }) => {
  const { data: technologies } = useTechnologiesQuery();

  const isSelectedClass = (techName: string) => {
    if (selectedTech.some((item) => item.name === techName)) {
      return styles.includedTechnologiesBtn;
    }
    return styles.technologiesBtn;
  };

  return (
    <div className={styles.technologiesBtnList}>
      {technologies?.map((tech) => (
        <button
          className={isSelectedClass(tech.name)}
          type="button"
          key={tech.name}
          onClick={() => setSelectedTech(tech)}
        >
          <div className={styles.iconContainer}>
            <img src={tech.icon} alt={tech.name} />
          </div>
          {tech.name}
        </button>
      ))}
    </div>
  );
};

export default TechnologiesButtons;
