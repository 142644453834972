import { Technology } from '../../../store/features/filters/models';
import TechnologiesButtons from '../../UI/TechnologiesButtons';

interface ITechnologiesFilter {
  selected: Technology[];
  onSelect(tech: Technology): void;
}

export default function TechnologiesFilter({ selected, onSelect }: ITechnologiesFilter) {
  return (
    <div className="d-flex justify-content-between align-items-center  bg-white pt-4 rounded-4">
      <p style={{ fontFamily: 'var(--font-gilroy)', fontWeight: 500 }} className="m-md-4">
        Technologies:
      </p>
      <div className="d-flex justify-content-end align-items-center flex-wrap">
        <TechnologiesButtons selectedTech={selected} setSelectedTech={onSelect} />
      </div>
    </div>
  );
}
