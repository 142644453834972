import { useMemo, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';

import AddNewFileButton from '../../../components/UI/AddNewFileButton';
import CVPreviewCard from '../../../components/UI/Card/CvPreview';
import DownloadButton from '../../../components/UI/DowloadButton';
import Loader from '../../../components/UI/Loader';
import { filterOnExistValue } from '../../../helpers/array';
import ROUTES from '../../../routes';
import { useAppSelector } from '../../../store';
import { REDUCER_CV } from '../../../store/features/cvs/constants';
import { filteredCVList } from '../../../store/features/cvs/selectors';
import { useGetCVsQuery } from '../../../store/features/cvs/service';
import { Staff } from '../../../store/features/cvs/types';

export default function CVListPage() {
  const navigate = useNavigate();
  const { data, status } = useGetCVsQuery('');
  const [selected, setSelected] = useState<string[]>([]);

  const list = useAppSelector((state) =>
    filteredCVList(data || [], state[REDUCER_CV].filters, state[REDUCER_CV].search),
  );

  const selectedPdfLinks = useMemo((): string[] => {
    if (list) {
      return list
        .filter((profile: Staff) => profile && profile?.id && selected.includes(profile?.id))
        .map((profile: Staff): string => profile?.cv_file || '');
    }
    return [];
  }, [selected, list]);

  const onSelect = (id: string) => {
    if (selected.includes(id)) {
      return setSelected(selected.filter((selectedId) => selectedId !== id));
    }
    return setSelected([...selected, id]);
  };

  const onDownload = () => {
    selectedPdfLinks.filter(filterOnExistValue).forEach((link) => {
      // TODO: temporary fix with replace
      window.open(link, '_blank', 'noopener,noreferrer');
    });
  };

  const onClickHandler = () => navigate(ROUTES.CV_ADD);

  return (
    <Container>
      <p className=" mh-2 w-100 d-flex justify-content-end">CVs {list?.length}</p>
      <div className="row d-flex justify-content-end">
        <DownloadButton selected={selectedPdfLinks} downloadAction={onDownload} />
      </div>
      {status === 'pending' ? (
        <Loader />
      ) : (
        <div className="row grid-layout">
          {list?.map((profile: Staff) => (
            <CVPreviewCard
              onSelect={onSelect}
              profile={profile}
              selected={!!profile?.id && selected.includes(profile?.id)}
              key={profile.id}
            />
          ))}
        </div>
      )}

      <AddNewFileButton onClick={onClickHandler} />
    </Container>
  );
}
