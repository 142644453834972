import { useAppDispatch, useAppSelector } from '../../../index';
import { REDUCER_PROJECT } from '../constants';
import { setSearch } from '../reducer';

export default function useProjectSearch() {
  const { search } = useAppSelector((state) => ({ search: state[REDUCER_PROJECT].search }));
  const dispatch = useAppDispatch();

  const setProjectSearch = (searchString: string) => dispatch(setSearch(searchString));

  return { search, setProjectSearch };
}
