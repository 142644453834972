import Icons, { IconType } from '../Icons';
import styles from './styles.module.scss';

function DownloadButton({ selected, downloadAction }: { selected: string[]; downloadAction(): void }) {
  const selectedLength = selected.length;
  if (!selectedLength) {
    return null;
  }

  return (
    <div className={styles['download-button']}>
      <span>
        {selectedLength} item{selectedLength > 1 && 's'} selected
      </span>
      <button type="button" onClick={downloadAction}>
        <Icons type={IconType.DownloadPDF} />
        Download PDF
      </button>
    </div>
  );
}

export default DownloadButton;
