import { useAppDispatch, useAppSelector } from '../../../index';
import { REDUCER_CV } from '../constants';
import { setSearch } from '../reducer';

export default function useCVSearch() {
  const { search } = useAppSelector((state) => ({ search: state[REDUCER_CV].search }));
  const dispatch = useAppDispatch();

  const setCVSearch = (searchString: string) => {
    dispatch(setSearch(searchString));
  };

  return { search, setCVSearch };
}
