import { yupResolver } from '@hookform/resolvers/yup';

import { signUpSchema, loginSchema } from './schema';

const signUpOptions: any = {
  mode: 'onSubmit',
  resolver: yupResolver(signUpSchema),
  defaultValues: { username: '', email: '', password: '' },
};

const loginOptions: any = {
  mode: 'onSubmit',
  resolver: yupResolver(loginSchema),
  defaultValues: { email: '', password: '' },
};

export { signUpOptions, loginOptions };
