import { ChangeEvent, FC, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import uploadCV from '../../assets/png/uploadCV.png';
import { Technology } from '../../store/features/filters/models';
import Button from '../UI/Button';
import FormInput from '../UI/FormInput';
import TechnologiesButtons from '../UI/TechnologiesButtons';
import UploadFiles from '../UI/UploadFiles';
import UploadImage from '../UI/UploadImage';
import styles from './styles.module.scss';

const positions = [
  'Front-End Developer',
  'Back-End Developer',
  'Data scientist',
  'Quality Assurance',
  'Project Manager',
];

const allowedExtensions = ['application/pdf'];
const allowedExtensionForImage = ['image/png', 'image/jpeg', 'image/jpg'];

const FormAddCV: FC = () => {
  const [selectedTech, setSelectedTech] = useState<Technology[]>([]);
  const [files, setFiles] = useState<FileList | File[] | null>(null);
  const [icon, setIcon] = useState<FileList | null>(null);
  const [formState, setformState] = useState({
    title: '',
    overview: '',
    about_product: '',
    solution: '',
    challenge: '',
    services: '',
    industry: '',
    result: '',
  });

  const handleSubmit = () => {
    const data = new FormData();
    Object.keys(formState).forEach((key: string) => {
      // TODO: remove this
      // @ts-ignore
      data.append(key, formState?.[key]);
    });

    selectedTech.forEach((tech) => {
      // data.append('technology', tech);
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, name: string) => {
    const value = e.target.value as string | FileList;
    setformState({
      ...formState,
      [name]: value,
    });
  };

  const handleTechSelect = (tech: Technology): void => {
    if (selectedTech.some((item) => item.name === tech.name)) {
      setSelectedTech((prev) => prev.filter((item) => item.name !== tech.name));
    } else {
      setSelectedTech([...selectedTech, tech]);
    }
  };

  return (
    <form className={styles.form}>
      <div className={styles.mainFormInfo}>
        <UploadImage
          setIcon={setIcon}
          subtitle="Drop card image here or"
          allowedExtensionForImage={allowedExtensionForImage}
        />
        <div className={styles.info}>
          <FormInput title="Name:" handleChange={handleChange} />

          <label htmlFor="position" className={styles.position}>
            <h4 className={styles.title}>Position:</h4>
            <select className={styles.select} name="position">
              {positions.map((pos) => (
                <option value={pos}>{pos}</option>
              ))}
            </select>
          </label>

          <FormInput title="Projects:" handleChange={handleChange} />

          <FormInput title="Experience:" handleChange={handleChange} />
        </div>
      </div>

      <div className={styles.secondaryFormInfo}>
        <div className={styles.infoSectionTechnologies}>
          <h4 className={styles.title}>Technologies:</h4>
          <TechnologiesButtons selectedTech={selectedTech} setSelectedTech={handleTechSelect} />
        </div>

        <div className={styles.infoSectionPdf}>
          <h4 className={styles.title}>CV:</h4>
          <UploadFiles
            allowedExtensions={allowedExtensions}
            image={uploadCV}
            subtitle="Drop File here or"
            files={files}
            setFiles={setFiles}
            multiple={false}
            accept="application/pdf"
          />
        </div>
      </div>
      <div className={styles.submitContainer}>
        <Button type="submit" className={styles.submit}>
          Submit
        </Button>
      </div>
    </form>
  );
};

export default FormAddCV;
