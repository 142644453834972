import classNames from 'classnames';
import { FC, ImgHTMLAttributes } from 'react';

import DevIcon from '../../assets/svg/Dev.svg';
import DownloadPDFIcon from '../../assets/svg/Download_PDF.svg';
import ExperienceIcon from '../../assets/svg/Experience.svg';
import ArrowDownIcon from '../../assets/svg/Frame_119.svg';
import IndustryIcon from '../../assets/svg/Inductry.svg';
import LanguageIcon from '../../assets/svg/Language.svg';
import LocationIcon from '../../assets/svg/Location.svg';
import LogoGreenIcon from '../../assets/svg/logo_2.svg';
import LogoWhiteIcon from '../../assets/svg/Logo_start.svg';
import PlatformIcon from '../../assets/svg/Platform.svg';
import ProjectIcon from '../../assets/svg/Project.svg';
import ProjectDurationIcon from '../../assets/svg/Project_Duraction.svg';
import ProjectLocationIcon from '../../assets/svg/Project_Location.svg';
import RefreshIcon from '../../assets/svg/Search_loading.svg';
import SpecialityIcon from '../../assets/svg/Speciality.svg';
import TechnologyIcon from '../../assets/svg/Technology.svg';
import TopRangeIcon from '../../assets/svg/Top_range.svg';

export enum IconType {
  Dev = 'Dev',
  DownloadPDF = 'DownloadPDF',
  Experience = 'Experience',
  ArrowDown = 'ArrowDown',
  Industry = 'Industry',
  Language = 'Language',
  Location = 'Location',
  LogoGreen = 'LogoGreen',
  LogoWhite = 'LogoWhite',
  Platform = 'Platform',
  ProjectDuration = 'ProjectDuration',
  ProjectLocation = 'ProjectLocation',
  Project = 'Project',
  Refresh = 'Refresh',
  Speciality = 'Speciality',
  Technology = 'Technology',
  TopRange = 'TopRange',
}

const IconByType = {
  [IconType.Dev]: DevIcon,
  [IconType.DownloadPDF]: DownloadPDFIcon,
  [IconType.Experience]: ExperienceIcon,
  [IconType.ArrowDown]: ArrowDownIcon,
  [IconType.Industry]: IndustryIcon,
  [IconType.Language]: LanguageIcon,
  [IconType.Location]: LocationIcon,
  [IconType.LogoGreen]: LogoGreenIcon,
  [IconType.LogoWhite]: LogoWhiteIcon,
  [IconType.Platform]: PlatformIcon,
  [IconType.ProjectDuration]: ProjectDurationIcon,
  [IconType.ProjectLocation]: ProjectLocationIcon,
  [IconType.Project]: ProjectIcon,
  [IconType.Refresh]: RefreshIcon,
  [IconType.Speciality]: SpecialityIcon,
  [IconType.Technology]: TechnologyIcon,
  [IconType.TopRange]: TopRangeIcon,
};

interface IIcons extends ImgHTMLAttributes<HTMLImageElement> {
  type: IconType;
}

const Icons: FC<IIcons> = ({ type, ...imageProps }) => (
  <img
    {...imageProps}
    alt={imageProps?.alt || type}
    className={classNames(imageProps.className, 'icon')}
    src={IconByType[type]}
  />
);

export default Icons;
