import { useLocation } from 'react-router-dom';

import ROUTES from '../../../routes';
import useCVFilters from '../../../store/features/cvs/adapters/useCVFilters';
import useCVSearch from '../../../store/features/cvs/adapters/useCVSearch';
import useProjectFilters from '../../../store/features/projects/adapters/useProjectFilters';
import useProjectSearch from '../../../store/features/projects/adapters/useProjectSearch';
import Icons, { IconType } from '../../UI/Icons';
import FilterButtonsList from './FilterButtonList';
import FiltersByRoute from './FiltersByRoute';
import './styles.scss';
import useCollapseState from './useCollapseState';

function FilterBar() {
  const { pathname } = useLocation();
  const path = pathname as ROUTES.CV_LIST | ROUTES.PROJECT_LIST;
  const { collapseState, setActive } = useCollapseState();

  const FilterButtons = FilterButtonsList[path];
  const Filters = FiltersByRoute[path];

  const { search: searchCV, setCVSearch } = useCVSearch();
  const { search: searchProject, setProjectSearch } = useProjectSearch();

  const { resetCVFilters } = useCVFilters();
  const { resetProjectFilters } = useProjectFilters();
  const { search, setSearch } = {
    [ROUTES.CV_LIST]: { search: searchCV, setSearch: setCVSearch },
    [ROUTES.PROJECT_LIST]: { search: searchProject, setSearch: setProjectSearch },
  }[path];
  const resetFilters = {
    [ROUTES.CV_LIST]: resetCVFilters,
    [ROUTES.PROJECT_LIST]: resetProjectFilters,
  }[path];

  return (
    <>
      <nav className="navbar navbar-expand-lg p-0">
        <div className="container">
          <div className="d-flex flex-row">
            <div className="filters">
              <FilterButtons active={collapseState} setActive={setActive} />
            </div>
          </div>

          <div className="search">
            <input type="text" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
            <Icons type={IconType.Refresh} onClick={resetFilters} />
          </div>
        </div>
      </nav>
      <div className="container container-fluid-xs mb-3 filter-options">
        <Filters collapseState={collapseState} />
      </div>
    </>
  );
}

export default FilterBar;
