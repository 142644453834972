import Button from '../../UI/Button';

interface IExperienceFilter {
  selected: Array<{ type: string }>;
  onSelect(experience: object): void;
}

export default function ExperienceFilter({ selected, onSelect }: IExperienceFilter) {
  const selectedTime = selected.length ? selected[0].type : '';

  return (
    <div className="d-flex justify-content-between align-items-center mt-2">
      Experience:
      <div className="d-flex justify-content-end flex-wrap">
        <Button
          variant={selectedTime === '1-3 years' ? 'secondary' : 'outline-secondary'}
          size="sm"
          onClick={() => onSelect({ min: 1, max: 3, type: '1-3 years' })}
        >
          1-3 years
        </Button>

        <Button
          variant={selectedTime === '4-6 years' ? 'secondary' : 'outline-secondary'}
          size="sm"
          onClick={() => onSelect({ min: 4, max: 6, type: '4-6 years' })}
        >
          4-6 years
        </Button>

        <Button
          variant={selectedTime === '7-9 years' ? 'secondary' : 'outline-secondary'}
          size="sm"
          onClick={() => onSelect({ min: 7, max: 9, type: '7-9 years' })}
        >
          7-9 years
        </Button>

        <Button
          variant={selectedTime === '10+ years' ? 'secondary' : 'outline-secondary'}
          size="sm"
          onClick={() => onSelect({ min: 10, max: 20, type: '10+ years' })}
        >
          10+ years
        </Button>

        {/* {list?.map((name: number) => {
          const isSelected = selected.includes(name as number)
          return (
            <Button
    //          variant={isSelected ? 'secondary' : 'outline-secondary'}
              size='sm'
            //  onClick={() => onSelect(name as number)}
              key={name}
            >
              {name} years
            </Button>
          )
        })} */}
      </div>
    </div>
  );
}
