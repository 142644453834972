import classNames from 'classnames';

import Icons, { IconType } from '../Icons';
import styles from './styles.module.scss';

type FilterItemExpanderType =
  | 'location'
  | 'industry'
  | 'experience'
  | 'speciality'
  | 'project'
  | 'platform'
  | 'technology'
  | 'language';

interface IFilterItemExpander {
  type: FilterItemExpanderType;
  isActive: boolean;
  setActive(isActive: boolean): void;
}

const Filters = {
  technology: {
    icon: IconType.Technology,
    name: 'Technology',
  },
  location: {
    icon: IconType.Location,
    name: 'Location',
  },
  industry: {
    icon: IconType.Industry,
    name: 'Industry',
  },
  experience: {
    icon: IconType.Experience,
    name: 'Experience',
  },
  speciality: {
    icon: IconType.Speciality,
    name: 'Speciality',
  },
  project: {
    icon: IconType.Project,
    name: 'Project',
  },
  platform: {
    icon: IconType.Platform,
    name: 'Platform',
  },
  language: {
    icon: IconType.Language,
    name: 'Language',
  },
};

export default function FilterItemExpander({ type, isActive, setActive }: IFilterItemExpander) {
  const { name, icon } = Filters[type];
  return (
    <button type="button" className={styles['filter-item-expander']} onClick={() => setActive(!isActive)}>
      <Icons className={styles.icon} type={icon} />
      <span>{name}</span>
      <Icons type={IconType.ArrowDown} className={classNames({ [styles.active]: !isActive })} />
    </button>
  );
}
