import { Viewer, Worker } from '@react-pdf-viewer/core';
import { FC } from 'react';
import { pdfjs } from 'react-pdf';

import cancelImg from '../../../../assets/svg/close-icon.svg';
import styles from './styles.module.scss';

type UploadedContentProps = {
  urlFiles: string[];
  image: string;
  cancelIcon: (arg: string, id?: number) => void;
  subtitle: string;
  accept: string;
};

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const UploadedContent: FC<UploadedContentProps> = ({ accept, urlFiles, image, cancelIcon, subtitle }) => {
  if (urlFiles.length && accept === 'application/pdf') {
    return (
      <>
        <button className={styles.cancelButtonPdfFile} type="button" onClick={() => cancelIcon(urlFiles[0])}>
          <img src={cancelImg} alt="cancel file" />
        </button>
        <Worker workerUrl={pdfjs.GlobalWorkerOptions.workerSrc}>
          <Viewer fileUrl={urlFiles[0]} />
        </Worker>
      </>
    );
  }

  if (urlFiles.length) {
    return (
      <div className={styles.uploadedImagesContainer}>
        {urlFiles.map((photo, id) => (
          <div key={photo} className={styles.uploadedImage}>
            <div className={styles.urlImagesContainer}>
              <img src={photo} alt="Uploaded" />
            </div>
            <button className={styles.cancelButton} type="button" onClick={() => cancelIcon(photo, id)} id={photo}>
              <img src={cancelImg} alt="cancel icon" />
            </button>
          </div>
        ))}
      </div>
    );
  }
  return (
    <div className={!urlFiles.length ? styles.visibleContainer : styles.hiddenContainer}>
      <div className={accept !== 'application/pdf' ? styles.screenshotContainer : styles.cvContainer}>
        <img src={image} alt="Upload" />
      </div>
      <p className={accept !== 'application/pdf' ? styles.paragraph : styles.shortParagraph}>{subtitle}</p>
    </div>
  );
};

export default UploadedContent;
