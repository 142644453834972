import { combineReducers } from '@reduxjs/toolkit';

import auth, { name as authName } from '../features/auth/reducer';
import { authApi } from '../features/auth/service';
import cvs, { name as cvsName } from '../features/cvs/reducer';
import { cvsApi } from '../features/cvs/service';
import { filtersApi } from '../features/filters/service';
import projects, { name as projectsName } from '../features/projects/reducer';
import { projectsApi } from '../features/projects/service';

export const rootReducer = combineReducers({
  [authName]: auth,
  [cvsName]: cvs,
  [projectsName]: projects,
  [filtersApi.reducerPath]: filtersApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [cvsApi.reducerPath]: cvsApi.reducer,
  [projectsApi.reducerPath]: projectsApi.reducer,
});
