import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FC } from 'react';

type ErrorHandlerProps = { error?: FetchBaseQueryError | SerializedError };

const ErrorComponent: FC<ErrorHandlerProps> = ({ error }) => {
  return (
    <div style={{ top: '24px' }} hidden={!error} className="alert alert-danger position-absolute" role="alert">
      {error && 'data' in error && Array.isArray(error.data) ? error.data[0] : error}
    </div>
  );
};

export default ErrorComponent;
