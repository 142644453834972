import { platformFilter } from '../../../store/features/projects/selectors';
import { useGetProjectsQuery } from '../../../store/features/projects/service';
import Button from '../../UI/Button';

interface IPlatformFilter {
  selected: string[];
  onSelect(industry: string): void;
}
export default function PlatformFilter({ selected, onSelect }: IPlatformFilter) {
  const { data: list } = useGetProjectsQuery('', {
    selectFromResult: (response) => ({
      ...response,
      data: platformFilter(response.data || []),
    }),
  });

  return (
    <div className="d-flex justify-content-between align-items-center mt-2">
      Platform:
      <div className="d-flex justify-content-end flex-wrap">
        {list?.map((name: string) => {
          const isSelected = selected.includes(name);
          return (
            <Button
              variant={isSelected ? 'secondary' : 'outline-secondary'}
              size="sm"
              onClick={() => onSelect(name)}
              key={name}
            >
              {name}
            </Button>
          );
        })}
      </div>
    </div>
  );
}
