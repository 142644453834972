import classnames from 'classnames';
import { FC, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import ROUTES from '../../../routes';
import Icons, { IconType } from '../../UI/Icons';
import styles from './styles.module.scss';

const Header: FC<{}> = () => {
  const { pathname } = useLocation();

  const List = useMemo(
    () => [
      { route: ROUTES.PROJECT_LIST, name: 'CASES' },
      { route: ROUTES.CV_LIST, name: 'CVs' },
    ],
    [],
  );

  const linkClassName = (currentRoute: string) =>
    classnames(styles['navigation-item'], {
      [styles.active]: currentRoute === pathname,
    });

  return (
    <header className="container">
      <Icons type={IconType.LogoGreen} />
      <nav>
        <ul className={styles.navigation}>
          {List.map(({ route, name }) => (
            <li key={name} className={linkClassName(route)}>
              <Link to={route}>{name}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
