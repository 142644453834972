import { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import { Link, useParams } from 'react-router-dom';

import arrowBack from '../../../assets/svg/arrow-back.svg';
import download from '../../../assets/svg/Download_PDF.svg';
import useWidth from '../../../helpers/useWidth';
import ROUTES from '../../../routes';
import { useGetCVByIdQuery } from '../../../store/features/cvs/service';
import styles from './styles.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const CVPage = () => {
  const wrapperDiv = useRef<HTMLDivElement>(null);
  const width = useWidth(wrapperDiv);

  const { id } = useParams();

  const { data: selectedCV } = useGetCVByIdQuery(id);
  const [allPages, setNumPages] = useState(null);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);

  const pdfUrl = (selectedCV?.cv_file && selectedCV?.cv_file.replace('http://', 'https://')) || '';

  const downloadPDF = () => {
    window.open(pdfUrl, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    const getPdf = async () => {
      const res = await fetch(pdfUrl, {
        method: 'GET',
      });
      const blob = await res.blob();

      setPdfBlob(blob);
    };
    getPdf();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <div className={styles.pageButtons}>
        <Link to={ROUTES.CV_LIST} style={{ margin: 0 }}>
          <button type="button">
            <img src={arrowBack} alt="goBack" />
          </button>
        </Link>

        <button type="button" className="button-s download" onClick={downloadPDF}>
          <img src={download} width="20" alt="download" />
          Download PDF
        </button>
      </div>

      <div className={styles.pageWrapper}>
        <div className={styles.cvWrapper} ref={wrapperDiv}>
          {pdfBlob && (
            <Document
              renderMode="canvas"
              file={pdfUrl}
              onLoadSuccess={({ numPages }: { numPages: any }) => setNumPages(numPages)}
            >
              {[...Array(allPages)].map((page, index) => (
                <Page key={page} pageNumber={index + 1} width={width || 0} />
              ))}
            </Document>
          )}
        </div>
      </div>
    </Container>
  );
};

export default CVPage;
