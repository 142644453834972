import styles from './styles.module.scss';

interface ISkill {
  name: string;
}
export default function Skill({ name }: ISkill) {
  return (
    <div className={styles.skill}>
      <span className={styles.skillName}>{name}</span>
    </div>
  );
}
