import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import ROUTES from '../../../routes';
import { useAppSelector } from '../../../store';
import { REDUCER_AUTH } from '../../../store/features/auth/constants';
import styles from './styles.module.scss';

const AuthLayout: FC = () => {
  const token = useAppSelector((state) => state[REDUCER_AUTH].token);

  if (token) {
    return <Navigate to={ROUTES.PROJECT_LIST} />;
  }

  return (
    <section className={styles['login-container']}>
      <figure className={styles['login-wallpaper']} />
      <Outlet />
    </section>
  );
};

export default AuthLayout;
