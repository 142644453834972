import { useCallback, useState } from 'react';

export interface CollapseBooleanState {
  [key: string]: boolean;
}

const initialState: CollapseBooleanState = {
  location: true,
  technology: true,
  industry: true,
  platform: true,
  speciality: true,
  experience: true,
  project: true,
  language: true,
};

export default function useCollapseState() {
  const [collapseState, setCollapse] = useState(initialState);
  const setActive = useCallback(
    (key: string) => (val: boolean) => setCollapse({ ...collapseState, [key]: val }),
    [collapseState],
  );

  return { collapseState, setActive };
}
